<template>
    <el-scrollbar style="height:100vh;">
        <div class="idxBoxiframe">
            <iframe style="width:100%;height: 100%;" :src="url" frameborder="0"></iframe>
        </div>
    </el-scrollbar>
</template>
  
<script>
import {
    allVr
} from "@/api";
import { } from "@/components";
export default {
    components: {

    },
    data() {
        return {
            url: ""
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        let url = this.$route.query.url;
        if (url) this.url = url

        allVr().then(res=>{
            let arr = res.data;
            let obj = arr.find(e=>{
                return e.status == 2
            })
            this.url = obj.url
        })
        // console.log(this.$route.query.url)

    },
    methods: {
    },
};
</script>
<style lang="scss" scoped></style>
  
  